import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Background
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  WHAT IS CRYPTOGRAPHY?
                </p>

                <List bulleted>
                  <List.Item>Cryptography is the practice and study of hiding information</List.Item>
                  <List.Item>It is a branch of both mathematics and computer science, and is affiliated closely with information theory, computer security, and engineering</List.Item>
                  <List.Item>The word "cryptography" is derived from the ancient Greek words "kryptos" (hidden) and "graphia" (writing).</List.Item>
                </List>

                <p style={{ fontSize: '1.33em' }}>
                  Terms in Cryptography
                </p>

                <List bulleted>
                  <List.Item>Plain text
                    <List.List>
                      <List.Item>a readable form for a message</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Cipher text
                    <List.List>
                      <List.Item>the encrypted form of a message</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Key word
                    <List.List>
                      <List.Item>a word used to encode/decode a message</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Encode
                    <List.List>
                      <List.Item>translate from plaintext to cipher text</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Decode
                    <List.List>
                      <List.Item>translate from cipher text back to plaintext</List.Item>
                    </List.List>
                  </List.Item>
                </List>

              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Project Objective
                </Header>

                <List bulleted>
                  <List.Item>to understand the terms “Cryptography”</List.Item>
                  <List.Item>to understand the basic algorithm of “Cypher”</List.Item>
                  <List.Item>to be able to create a program that canperform encryption and decryption ofCypher</List.Item>
                </List>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Summary
                </Header>

                <List bulleted>
                  <List.Item>Cryptography is the practice and study of hiding information</List.Item>
                  <List.Item>Cryptography is like a peanut butter jelly sandwich</List.Item>
                  <List.Item>encryption : Plain text -> Cipher text</List.Item>
                  <List.Item>decryption : Cipher text -> Plain text</List.Item>
                  <List.Item>key word is needed to process encryption/decryption</List.Item>
                </List>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row textAlign='center'>
              <Grid.Column style={{ paddingBottom: '0em', paddingTop: '0em' }}>
                <Image src={require('../img/projects/Vigenère Cipher/1.png')} />
                <p style={{ fontSize: '1.33em' }}>Architecture Picture</p>

                <Image src={require('../img/projects/Vigenère Cipher/2.png')} />
                <p style={{ fontSize: '1.33em' }}>Vigenère Cipher</p>

                <Image src={require('../img/projects/Vigenère Cipher/3.png')} />
                <p style={{ fontSize: '1.33em' }}>Code Example(Encryption)</p>

                <Image src={require('../img/projects/Vigenère Cipher/4.png')} />
                <p style={{ fontSize: '1.33em' }}>Code Example(Decryption)</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
