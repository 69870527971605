import React from "react";
import { Link } from "react-router-dom";
import { Card, Segment, Container, List, Header } from "semantic-ui-react";
import StackGrid, { transitions, easings } from "react-stack-grid";

import ResearchForm from "../forms/ResearchForm";
import researchData from "../data/research";
const transition = transitions.scaleDown;

const CardGroups = () => (
  <Segment style={{ padding: "2em 0em" }} vertical>
    <Container>
      <Header as="h3" style={{ fontSize: "2em" }}>
        Undergraduate Research
      </Header>
      <List divided relaxed>
        {researchData.map(research => (
          <List.Item key={research.name}>
            <List.Icon name='idea' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as={Link} to={`research/${research.name}`}><b>{research.participates}</b>, {research.name} </List.Header>
              <List.Description>{research.year}</List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Container>
  </Segment>
);

export default CardGroups;
