import React from 'react'
import { Modal, Header, Button, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import ImageGallery from "react-image-gallery";

class MyModal extends React.Component {

  confirmClick = (event, data) => {
    console.log("Passed in Prop Value: " + this.props.valueIntoModal);
    this.props.handleClose();
  }

  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        size='small'
        closeOnEscape={true}
        onClose={() => this.props.handleClose()}
      >
        <Header icon='browser' content='View' />
        <Modal.Content>
          <ImageGallery items={this.props.images} startIndex={this.props.startIndex} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            type='button'
            icon='remove'
            labelPosition='right'
            onClick={this.props.handleClose}
            content='Cancel'
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

MyModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  valueIntoModal: PropTypes.string.isRequired,
  startIndex: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.object)
}

export default MyModal
