import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid,
  Embed
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import Footer from "../common/footer";
import Subway_Soiree_img from "../img/journeys/MusicIsUniversal/Subway_Soiree.jpg";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Container text>

              <Header as='h3' style={{ fontSize: '2em' }}>
               I AM HORWANG
              </Header>
              <br/>
              <p style={{ fontSize: '1.33em' }}>
                A short drawing story is a fictional story based on a true story from my childhood when I didn't see how shcool is important.
                <br/>
                If you want to know more story, watch the video and turn on subtitle as you like.
              </p>

              <List bulleted>
                <List.Item>Story by: Panupong Leenawarat</List.Item>
                <List.Item>Drawing by: Panupong Leenawarat; Chayanid Siriwatanapat</List.Item>
                <List.Item> Sound By
                  <List.List>
                    <List.Item>Bruce, Bert, Kyle: Panupong Leenawarat</List.Item>
                    <List.Item>Mary, Clara: Thanraya Thangsirichuai</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>Editior By: Panupong Leenwarat</List.Item>
              </List>

              <Divider
                as="h4"
                className="header"
                horizontal
                style={{ margin: "3em 0em", textTransform: "uppercase" }}
              >
                <a>OVERVIEW</a>
              </Divider>

              <div>
                <Embed
                  autoplay
                  id="AwQZjrlfUfE"
                  placeholder={require(`../img/journeys/I AM HORWANG/cover.jpg`)}
                  source="youtube"
                  autoplay
                  hd
                />
                <Divider />
              </div>
            </Container>
          </Grid.Row>
        </Grid>
      </Segment>


        <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
