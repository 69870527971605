import React from "react";
import { Route } from "react-router-dom";
import TopNavigation from "./components/nevigation/TopNavigation";
import HomePage from "./components/pages/HomePage";
import MainPage from "./components/pages/MainPage";
import ProfilePage from "./components/pages/ProfilePage";
import ProjectPage from "./components/pages/ProjectPage";
import TimelinePage from "./components/pages/TimelinePage";
import SubProjectPage from "./components/pages/SubProjectPage";
import JourneyPage from "./components/pages/JourneyPage";
import SubJourneyPage from "./components/pages/SubJourneyPage";
import ResearchPage from "./components/pages/ResearchPage";
import SubResearchPage from "./components/pages/SubResearchPage";
import SubGalleryPage from "./components/pages/SubGalleryPage";
import GalleryPage from "./components/pages/GalleryPage";
import PeriodicTable from "./components/pages/PeriodicTable/PeriodicTable";

import "./App.css";

const App = () => (
  <div>
    <div>
      <TopNavigation>
        <Route path="/" exact component={HomePage} />
        <Route path="/main" exact component={MainPage} />
        <Route path="/profile" exact component={ProfilePage} />
        <Route path="/projects" exact component={ProjectPage} />
        <Route path="/research" exact component={ResearchPage} />
        <Route path="/timeline" exact component={TimelinePage} />
        <Route path="/gallery" exact component={GalleryPage} />
        <Route path="/journey" exact component={JourneyPage} />
        <Route path="/periodicTable" exact component={PeriodicTable} />
        <Route path="/projects/:projectName" exact component={SubProjectPage} />
        <Route path="/journey/:journeyName" exact component={SubJourneyPage} />
        <Route path="/research/:researchName" exact component={SubResearchPage} />
        <Route path="/gallery/:galleryName" exact component={SubGalleryPage} />
      </TopNavigation>
    </div>
  </div>
);

export default App;
