import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import Footer from "../common/footer";
import img1 from "../img/journeys/apolloPoem/1.jpg";
import img2 from "../img/journeys/apolloPoem/2.jpg";
import img3 from "../img/journeys/apolloPoem/3.jpg";
import img4 from "../img/journeys/apolloPoem/cover.jpg";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Header as='h3' style={{ fontSize: '2em' }}>
               Apollo Poem 69th Anniversary
              </Header>

              <Container text>

                <p style={{ fontSize: '1.33em' }}>
                  In the month of July in the year of sixty-ninth
                </p>
                <p style={{ fontSize: '1.33em' }}>
                The two men drove their rocket landing on the moon
                </p>
                <p style={{ fontSize: '1.33em' }}>
                Be braved men, Mr. Neil Armstrong, Colonel Aldrin
                </p>
                <p style={{ fontSize: '1.33em' }}>
                The people on the planet praying the men make it through.
                </p>
              </Container>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Container>
          <Image bordered rounded size='medium' src={img4} centered />
        </Container>
      </Segment>


      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Container text>
                <p style={{ fontSize: '1.33em' }}>
                The Apollo eleventh driven on the way to the moon
                </p>
                <p style={{ fontSize: '1.33em' }}>
                Six hours arrived the moon, the giant moment wind
                </p>
                <p style={{ fontSize: '1.33em' }}>
                When the first man stepped down on the lunar, said
                </p>
                <p style={{ fontSize: '1.33em' }}>
                "That's one small step for man, one giant leap for mankind."
                </p>
              </Container>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ padding: '0em' }}>
                  <Image bordered rounded size='medium' src={img1} centered />
                </Grid.Column>
                <Grid.Column style={{ padding: '0em' }}>
                  <Image bordered rounded size='medium' src={img2} centered />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '8em 0em' }} vertical>
            <Container text>
              <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                  <Container text>
                    <p style={{ fontSize: '1.33em' }}>
                    When Neil planted the flag and collected rocks,
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Neil picked dust up loosely with his toe.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Because of gravity, walked on moon was comfortable
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    One small blue planet watched in awe.
                    </p>
                  </Container>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>

          <Segment style={{ padding: '8em 0em' }} vertical>
            <Container text>
              <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                  <Container text>
                    <p style={{ fontSize: '1.33em' }}>
                    President Nixon talked with the guys from the Sea of Tranquility
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    One priceless moment, their pride unites all people to a string
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Dream fulfilled, landing the men on the moon and returning safely to earth
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    On prime moment, the aims of decade have been achieved.
                    </p>
                  </Container>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>

          <Segment style={{ padding: '8em 0em' }} vertical>
            <Container text>
              <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                  <Container text>
                    <p style={{ fontSize: '1.33em' }}>
                    The men walked on the moon was possible man's reach
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    In hours walked on the moon, the new age of spacefaring began
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    The historic landing will live until the end of the time
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    In orbit, flew alone Michael Collins waiting these men to return
                    </p>
                  </Container>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>

        <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
