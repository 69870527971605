import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

class Nqueens extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Description:
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Given below is a random placement of four queens on a 4 × 4 chess board, where the board is represented by one number per column, denoting which row the queen is in.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Tasks
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    The program takes n as an input and return the N-Queens solution for an n × n board
                  </p>
                  <Header as='h4' style={{ fontSize: '1.5em' }}>
                    1. Implemented heristic function, which count how many queen in the same row or a diagonal
                  </Header>

                  <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                      <Grid.Column style={{}}>
                        <Header as='h4' style={{ fontSize: '1.5em' }}>
                          Code Snippet
                        </Header>
                        <AceEditor
                          placeholder="Placeholder Text"
                          mode="python"
                          theme="monokai"
                          name="blah2"
                          width="100%"
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={`
        def fitness_function(configuration):
        """Compute the fitness function for a given board configuration.

        Arguments:
            configuration (list[int]): N-Queen board configuration represented as a list of row indices.

        Returns:
            The fitness function value (number of attacking pairs)

        """
        heuristic = 0

        # Check every row and column
        for col in range(len(configuration)):
            for row in range(col + 1, len(configuration)):
                # If Queens are in the same row
                if configuration[col] is configuration[row]:
                    heuristic += 1 # increase heuristic

                offset = row - col
                # Check a diagonal
                if configuration[col] is configuration[row] - offset or configuration[col] is configuration[row] + offset:
                    heuristic += 1 # increase heuristic

        return heuristic
                          `}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        }}/>
                        <p style={{ fontSize: '1.33em' }}>Code 1. Compute the fitness function for a given board configuration</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Header as='h4' style={{ fontSize: '1.5em' }}>
                    2. Implemented two different solutions to the N-Queens problems
                  </Header>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                    •	Hill Climbing
                  </Header>

                  <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                      <Grid.Column style={{ paddingBottom: '5em' }}>
                        <Header as='h4' style={{ fontSize: '1.5em' }}>
                          Code Snippet
                        </Header>
                        <AceEditor
                          placeholder="Placeholder Text"
                          mode="python"
                          theme="monokai"
                          name="blah2"
                          width="100%"
                          height="300px"
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={`
  def run_hill_climbing(self):
      """Run the hill climbing algorithm.

      1. This function updates self.configuration and self.found_solution appropriately as you run the algorithm.
      2. This function makes iterative calls to self.make_hill_climbing_move for each step.
      """
      steps = 0
      while fitness_function(self.configuration) > 0:
          self.make_hill_climbing_move()
          #print("Step: ",steps ," After move Heuristic:",fitness_function(self.configuration), "Board: ", self.configuration)
          if steps > self.threshold_steps:
              return
          steps += 1

      self.found_solution = True
                          `}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        }}/>
                        <p style={{ fontSize: '1.33em' }}>Code 2. the function runs the hill climbing algorithm.</p>

                        <AceEditor
                          placeholder="Placeholder Text"
                          mode="python"
                          theme="monokai"
                          name="blah2"
                          width="100%"
                          height="300px"
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={`
  def make_hill_climbing_move(self):
      """Make one move using hill climbing.

      1. This function updates self.configuration by one step using fitness_function.
      """
      moves = {} # Dict
      possible_moves = []

      for col in range(self.size):
          for row in range(self.size):
              if self.configuration[col] is row:
                  #print(self.configuration, fitness_function(self.configuration))
                  continue  # Skip since we know heuristic of current

              board_neighbor = list(self.configuration)
              board_neighbor[col] = row       # Move the queen to the new row
              #print(board_neighbor, fitness_function(board_neighbor))
              moves[(col, row)] = fitness_function(board_neighbor)

      current_h = fitness_function(self.configuration)
      # set the current_h to the lowest next_h
      for position, next_h in moves.items():
          if next_h < current_h:
              current_h = next_h

      for position, next_h in moves.items():
          if next_h is current_h:
              possible_moves.append(position)

      # In case there have more than one move which has the same heuristic
      size_move = len(possible_moves)
      if size_move is not 0:
          #create randomly select
          select = random.randint(0, size_move - 1)
          col, row = possible_moves[select][0], possible_moves[select][1]
          self.configuration[col] = row
          `}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        }}/>
                        <p style={{ fontSize: '1.33em' }}>Code 3. the function makes one move using hill climbing</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Header as='h3' style={{ fontSize: '2em' }}>
                    •	Simulated Annealing
                  </Header>

                  <Grid celled='internally' columns='equal' stackable>
                    <Grid.Row textAlign='center'>
                      <Grid.Column style={{ paddingBottom: '5em' }}>
                        <Header as='h4' style={{ fontSize: '1.5em' }}>
                          Code Snippet
                        </Header>
                        <AceEditor
                          placeholder="Placeholder Text"
                          mode="python"
                          theme="monokai"
                          name="blah2"
                          width="100%"
                          height="300px"
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={`
  def run_simulated_annealing(self, temperature, anneal_rate):
      """Run the simulated annealing algorithm.

      Arguments:
          temperature (float): Initial temperature for simulated annealing.
          anneal_rate (float): The rate at which the temperature is annealed.

      1. This function updates self.configuration and self.found_solution appropriately as you run the algorithm.
      2. This function makes iterative calls to self.make_annealing_move for each step.
      """
      steps = 0
      while fitness_function(self.configuration) > 0:
          self.make_annealing_move(temperature)
          # Garantee that temp > 0, reduce temp
          temperature = max(temperature * anneal_rate, 0.01)
          if steps > self.threshold_steps:
              return
          steps += 1
      self.found_solution = True
                          `}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        }}/>
                        <p style={{ fontSize: '1.33em' }}>Code 2. the function runs the hill climbing algorithm.</p>

                        <AceEditor
                          placeholder="Placeholder Text"
                          mode="python"
                          theme="monokai"
                          name="blah2"
                          width="100%"
                          height="300px"
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={`
  def make_annealing_move(self, temperature):
      """Make one move using simulated annealing.

      Arguments:
          temperature (float): Current temperature.

      1. This function updates self.configuration by one step using fitness_function.
       """
      curr_h = fitness_function(self.configuration)
      end_move = False

      if temperature is 0:
          return

      while not end_move:
          # randomly selected successor
          new_col, new_row = random.randint(0, self.size - 1), random.randint(0, self.size - 1)

          board_next = list(self.configuration)
          board_next[new_col] = new_row
          next_h_cost = fitness_function(board_next)

          # next_h_cost - curr_h can produce negative value so,...
          delta_E = curr_h - next_h_cost
          if delta_E > 0:
              end_move = True
          else:
              # probability = math.exp(delta_e / temperature)
              # probability must be less than or equal to 1
              # accept_probability = min(1, probability)
              # make random with probability; greater probability, more likely to select the successor
              end_move = random.random() <= min(1, math.exp(delta_E / temperature))

      self.configuration = board_next
          `}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        }}/>
                        <p style={{ fontSize: '1.33em' }}>Code 3. the function makes one move using hill climbing</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Example:
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    The problem shows the next two moves for the board using hill climbing with the number of attacking pairs heuristic. The new board configurations represent as a list where the index represents the column and the value represents the row where the queen is present in that row. For reference, the representation of the initial board configuration is given.
                  </p>
                  <Image src={require('../img/projects/Nqueens/1.png')} size="medium" centered />

                  <p>
                    The heuristic of the given board [3, 2, 3, 0] is 5
                    <br/>
                    <br/>
                    <strong>
                    Step 1
                    </strong>
                    <br/>
                    Move a queen in column: 0
                    <br/>
                    [0, 2, 3, 0] heuristic: 3
                    <br/>
                    [1, 2, 3, 0] heuristic: 4
                    <br/>
                    [2, 2, 3, 0] heuristic: 3
                    <br/>
                    [3, 2, 3, 0] heuristic: 5
                    <br/>
                    Move a queen in column: 1
                    <br/>
                    [3, 0, 3, 0] heuristic: 3
                    <br/>
                    [3, 1, 3, 0] heuristic: 2
                    <br/>
                    [3, 2, 3, 0] heuristic: 5
                    <br/>
                    [3, 3, 3, 0] heuristic: 4
                    <br/>
                    Move a queen in column: 2
                    <br/>
                    [3, 2, 0, 0] heuristic: 4
                    <br/>
                    [3, 2, 1, 0] heuristic: 6
                    <br/>
                    [3, 2, 2, 0] heuristic: 4
                    <br/>
                    [3, 2, 3, 0] heuristic: 5
                    <br/>
                    Move a queen in column: 3
                    <br/>
                    [3, 2, 3, 0] heuristic: 5
                    <br/>
                    [3, 2, 3, 1] heuristic: 3
                    <br/>
                    [3, 2, 3, 2] heuristic: 5
                    <br/>
                    [3, 2, 3, 3] heuristic: 5
                    <br/>
                    Choose the board that has the least heuristic
                    <br/>
                    At step: 1, pick board configuration: [3, 1, 3, 0] which its heuristic is 2
                    <br/>
                    [QXQX]
                    <br/>
                    [XXXX]
                    <br/>
                    [XQXX]
                    <br/>
                    [XXXQ]
                    <br/>
                    <br/>
                    <strong>
                    Step 2
                    </strong>
                    <br/>
                    Move a queen in column: 0
                    <br/>
                    [0, 1, 3, 0] heuristic: 2
                    <br/>
                    [1, 1, 3, 0] heuristic: 2
                    <br/>
                    [2, 1, 3, 0] heuristic: 1
                    <br/>
                    [3, 1, 3, 0] heuristic: 2
                    <br/>
                    Move a queen in column: 1
                    <br/>
                    [3, 0, 3, 0] heuristic: 3
                    <br/>
                    [3, 1, 3, 0] heuristic: 2
                    <br/>
                    [3, 2, 3, 0] heuristic: 5
                    <br/>
                    [3, 3, 3, 0] heuristic: 4
                    <br/>
                    Move a queen in column: 2
                    <br/>
                    [3, 1, 0, 0] heuristic: 3
                    <br/>
                    [3, 1, 1, 0] heuristic: 4
                    <br/>
                    [3, 1, 2, 0] heuristic: 2
                    <br/>
                    [3, 1, 3, 0] heuristic: 2
                    <br/>
                    Move a queen in column: 3
                    <br/>
                    [3, 1, 3, 0] heuristic: 2
                    <br/>
                    [3, 1, 3, 1] heuristic: 2
                    <br/>
                    [3, 1, 3, 2] heuristic: 2
                    <br/>
                    [3, 1, 3, 3] heuristic: 4
                    <br/>
                    Choose the board that has the least heuristic
                    <br/>
                    At step: 2, pick board configuration: [2, 1, 3, 0] which its heuristic is 1
                    <br/>
                    [XXQX]
                    <br/>
                    [QXXX]
                    <br/>
                    [XQXX]
                    <br/>
                    [XXXQ]
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
      </div>
    );
  }
}

export default Nqueens;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
