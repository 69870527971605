import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import Footer from "../common/footer";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Header as='h3' style={{ fontSize: '2em' }}>
                Code Writer = Author
              </Header>
              <p style={{ fontSize: '2.33em' }}>
                <span style={{ fontSize: '2.33em' }}>“</span>I like to think of myself, actually as not a code writer but an author<span style={{ fontSize: '2.33em' }}>“</span>
                <br/>
              </p>
              <p style={{ fontSize: '1.33em' }}>
                The numbers 0 and 1 are enjoyable things to me. I can make 0 and 1 to anything. I was able to see the personality trails that I had left behind every time I went back to my previous codes because...
                </p>

              <p style={{ fontSize: '1.33em' }}>
                Since the beginning of "Hello World" programming, programmers like us have written things that no one else but computers care about reading. If the term "author" does not refer to a writer who writes for the purpose of being read by humans, programmers are unquestionably considered to be authors.              </p>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Container>
          <Image bordered rounded size='huge' src={require('../img/journeys/codeAuthor/1.jpg')} centered />
        </Container>
      </Segment>





        <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <p style={{ fontSize: '1.33em' }}>
                It has been questioned as to how creativity can be combined with a solid logical structure. Works without flexibility do not tolerate changes, and a single small bug can cause the entire program to crash. Contrary to this, creative works will provide an infinite amount of room to grow and develop. Every setback will help to make the program stronger and better in the long run...
                <br/>
                <br/>
                <p style={{ fontSize: '2.33em' }}>
                  <span style={{ fontSize: '2.33em' }}>“</span>Programming is an art. It requires aesthetic expression of thoughts. Machine code is written for the computers by the compilers, but the programs are written for humans by humans.<span style={{ fontSize: '2.33em' }}>“</span>
                </p>
                The process of writing code and writing a book is the same. It all started with brainstorming new ideas and jotting them down on paper or a computer. The text or program will be delivered to an QA for review before it is published. The editor will take on the role of a quality assurance analyst and will request that certain sections be removed, added, or changed. After that, even though the work has been delivered to readers or users for a long period of time, updates will be required.
                <br/>
                <br/>
                Novel, poetry, screenplay, and story can all be represented in one program. So, what we do is unquestionably some form of fiction, and we are the ones who have written it.
              </p>

            </Grid.Row>
          </Grid>
        </Container>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
          <Container>
            <Image bordered rounded size='large' src={require('../img/journeys/codeAuthor/2.jpeg')} centered />
          </Container>
        </Segment>

        <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
