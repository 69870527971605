import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
              <Header as='h3' style={{ fontSize: '2em' }}>
                Perform classification using a SVM on the tumor dataset
              </Header>

              <p style={{ fontSize: '1.33em' }}>
                Train an SVM classifier using the kernels shown in the below table
              </p>

              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/TumorDetection/1.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimates of the out of sample accuracy</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Summary
                  </Header>
                  <List bulleted>
                    <List.Item>The model selection method: cross_val_score with kfold = 7</List.Item>
                    <List.Item>The highest estimated out of sample accuracy on train set: 0. 97218</List.Item>
                    <List.Item>The classification accuracy on the public leader board on test set: 0.96551</List.Item>
                    <List.Item> The highest estimated out of sample accuracy on train set is close to the classification
                    accuracy on the public leader board on test set. Thus, SVM: C = 1, γ = 0.001, RBF is the best fit model among the given parameters and dataset attributes.
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Segment>
            <Segment style={{ padding: '0em' }} vertical>
              <Grid celled='internally' columns='equal' stackable>
                <Grid.Row textAlign='center'>
                  <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                    <Image src={require('../img/projects/TumorDetection/2.png')} />
                    <p style={{ fontSize: '1.33em' }}>Kaggle Dashboard</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
