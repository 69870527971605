import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import Footer from "../common/footer";
import img1 from "../img/journeys/The Missing Charge/cover.jpg";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Header as='h3' style={{ fontSize: '2em' }}>
               The Missing Charge
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                The identity between a negligent murder and an intended murder is that both murders cause someone to die, but a verdict unexpectedly approves that negligent manslaughter is not guilty as appearing in the movie “A Few Good Men.” According to in the movie is Lance Corporal Harold Dawson and Private Louden Downey, who were accused of killing Private William Santiago carried out a “Code Red” is an extrajudicial punishment order that they could not disobey the order from the superior, Lieutenant Kendrick who passed the order from Colonel Nathan Jessup (Yang). Noticeably, Downey and Dowson’s execution, which was the cause of Santiago’s death was not guilty on the charge of conspiracy to commit murder and the charge of murder, but the movie does not mention the charge of criminally negligent homicide although they were guilty. It seems as appropriate that they should have been the charge because their execution caused a person to die involuntarily, their case should have been a judicial precedent pattern, and they perjured about how they heard the order from.
              </p>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Container>
          <Image bordered rounded size='huge' src={img1} centered />
        </Container>
      </Segment>

        <Segment style={{ padding: '8em 0em' }} vertical>
          <Container text>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <p style={{ fontSize: '1.33em' }}>
                  The situation, which happened in the movie has also occurred in the real life that a marine caused another marine to die by a negligence. In contrast of verdicts, an accused in the real life case was convicted on the charge of negligent homicide and sentenced. On 8 April 2014, base officials said Lance Corporal Mark Boterf was shot and killed while on duty at the main gate of Camp Lejeune by Lance Corporal Brandon Little.
                </p>
                <p style={{ fontSize: '1.33em', paddingLeft: '2.5rem' }}>
                  On the scene, when traffic slacked off at the main gate, Little would play with the M4's safety, switching it on and off. At the end of the shift, Little said he went back to the guard shack to dismantle the weapon and did not notice the rifle had racked itself back as he was taking it off. Little said it caught on his walkie-talkie, which was around his neck. Little testified that the rifle’s safety was off which caused the weapon fired and hit Boterf’s chest (Swain).
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  At the martial court, Little admitted for negligent shooting the fellow Marine. Thus, government prosecutors accused Little on the charge of negligent homicide and asked Little to be sentenced in a brig, and discharged from the Marine Corps. Accordingly, juries should have judged and sentenced the accused in the movie was guilty as well as Lance Corporal Brandon Little’s case.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  “A judicial precedent means the process whereby judges follow previously decided cases where the facts are of sufficient similarity” (LawTeacher). A binding precedent is a decided case, which a court must follow, but Little’s case was mentioned implies that the followed case did not have the same or similar in the movie decision. The martial court decided to sentence Little for three years in a brig and when he gets released, Little is going to be demoted to private, and then given a dishonorable discharge (Swain). Clearly, if Downey and Dowson’s case happened in the real life, it was possible that the case would be a binding precedent, which causes Little’s case to have the similar verdict and sentence.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  This movie shows that Downey and Dowson avoided their guilty by giving just one side of information. Meanwhile, they had to perjure about how they heard the “Code Red” order from whom. However, they admitted the truth at the end of the inquiry. For example, Cpl. John D. Zuchnovich who was a marine was sentenced to six months of confinement and a bad conduct discharge after being convicted of perjury in a martial court (Jacksonville). The information is strong enough to support that Downey was guilty on the charge of perjury in a court. However, they were decided that he has been not guilty on the charge of murder; however: he confessed the truth afterward Therefore, the charge of perjury was ineffective. Conversely, the assumption that he was guilty on the charge of negligent manslaughter would affect he was guilty on the charge of perjury also in the real life decision.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  Clearly, Downey and Dowson should have been accused on the charge of negligent homicide because their execution caused Santiago’s death involuntarily although the case has not used as it is a binding precedent. In addition, they should have been accused on the charge of perjury. Fortunately, the decision in the movie did not happen in the real life because it would be the binding precedent that approves a negligent manslaughter is not guilty and releases offenders without an arrest or sentence.
                </p>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
            <Header as='h4'>
              Work Cited
            </Header>
            <List bulleted>
              <List.Item>Yang, Jeff. "Afghanistan Hazing Cases Echo ‘A Few Good Men’." The Wall Street Journal. 27 Dec. 2011. Web. 12 Nov. 2011.</List.Item>
              <List.Item>Swain, Carly. "Marine Gets Two Years in Brig for Lejeune Main Gate Shooting Death." Eastern Carolina Breaking News. 8 Oct. 2014. Web. 10 Nov. 2015.</List.Item>
              <List.Item>Jacksonville. "Marine Guilty of Perjury." Wilmington Morning Star 15 Feb. 1986: 2C. Web. 15 Nov. 2015.</List.Item>
              <List.Item>"Judicial Precedent 1." LawTeacher.net (2013): All Answers Ltd. Web. 14 Nov. 2015.</List.Item>
            </List>
            </Grid.Row>
          </Grid>
        </Container>
        </Segment>

        <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
