import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import GifPlayer from "react-gif-player";

import { Container } from "reactstrap";
import researchData from "../data/research";
import Nqueen from "../pages/game/N-queen/Nqueen";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

export default class SubResearchPage extends Component {
  state = { activeIndex: 0, numPages: null, pageNumber: 1 };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber - 1 === 0 ? state.numPages : state.pageNumber - 1
    }));

  goToNextPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber + 1 === state.numPages + 1 ? 1 : state.pageNumber + 1
    }));

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  research = researchData.find(
    item => item.name === this.props.match.params.researchName
  );

  render() {
    const { activeIndex } = this.state;
    const { active } = this.state;
    const { pageNumber, numPages } = this.state;
    return (
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em" }}>
            {this.research.name}
          </Header>
          {this.research.date && (<Header as='h4'>{this.research.date}</Header>)}

          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              Description
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                {this.research.description
                  ? this.research.description
                  : this.research.shortDescription}
              </p>
            </Accordion.Content>

            {this.research.team && (
              <div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Team members
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <List animated bulleted verticalAlign="middle">
                    {this.research.team.map(person => (
                      <List.Item key={person}>
                        <p>{person}</p>
                      </List.Item>
                    ))}
                  </List>
                </Accordion.Content>
              </div>
            )}
            {this.research.award && (
              <div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Award
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>{this.research.award}</p>
                </Accordion.Content>
              </div>
            )}
            {(this.research.demo || this.research.sourceCode) && (
              <div>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Link
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <List animated verticalAlign="middle">
                    {this.research.demo && (
                      <List.Item>
                        <List.Icon name="play circle" />
                        <List.Content>
                          <a href={this.research.demo} target="blank">DEMO</a>
                        </List.Content>
                      </List.Item>
                    )}
                    {this.research.sourceCode && (
                      <List.Item>
                        <List.Icon name="code" />
                        <List.Content>
                          <a href={this.research.sourceCode} target="blank">Source</a>
                        </List.Content>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>
              </div>
            )}
          </Accordion>

          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
            <a>OVERVIEW</a>
          </Divider>
          {this.research.gif && (
            <Grid centered>
              {this.research.gif.map(file => (
                <div>
                  {file[0] && (
                    <Header
                      as="h3"
                      style={{ fontSize: "1.5em", marginTop: "1.5em" }}
                      textAlign="left"
                    >
                      {file[0]}
                    </Header>
                  )}
                  <GifPlayer
                    style={{
                      width:
                        window.innerWidth <= 500 ? window.innerWidth : "100%"
                    }}
                    gif={require(`../img/research/${this.research.name}/${
                      file[1]
                    }`)}
                    autoplay
                  />
                </div>
              ))}
            </Grid>
          )}
          {this.research.pdf && (
            <Grid centered>
              <Grid.Row>
                <div
                  style={{
                    alignItems: "center",
                    verticalAlign: "middle"
                  }}
                >
                <Grid>
                  <Grid.Column floated='left' width={5}>
                    <Button
                      onClick={this.goToPrevPage}
                      content="Prev"
                      icon="left arrow"
                      labelPosition="left"
                    />
                    <Button
                      onClick={this.goToNextPage}
                      content="Next"
                      icon="right arrow"
                      labelPosition="right"
                    />
                  </Grid.Column>
                  <Grid.Column floated='right' width={5}>
                    <Button
                      as="a"
                      target="_blank"
                      href={require(`../data/pdf/${this.research.pdf}`)}
                      content="Download"
                      icon="download"
                      labelPosition="right"
                    />
                  </Grid.Column>
                </Grid>
                <Segment>

                  <Document
                    file={require(`../data/pdf/${this.research.pdf}`)}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={window.innerWidth <= 500 ? "0.6" : "1.5"}
                    />
                  </Document>
                  </Segment>
                </div>
              </Grid.Row>

              <p>
                Page {pageNumber} of {numPages}
              </p>
            </Grid>
          )}
          {this.research.img &&
            this.research.img.map(image => (
              <Grid centered key={image}>
                <div>
                  <Image
                    src={require(`../img/research/${
                      this.research.name
                    }/${image}`)}
                  />
                </div>
              </Grid>
            ))}
          {this.research.name === "N-Queen" && (
            <Grid centered>
              <Nqueen />
            </Grid>
          )}
          <Header as="h3" style={{ fontSize: "2em" }}>
            Panupong Leenawarat
          </Header>
          <div>
            <Button circular color="facebook" icon="facebook" />
            <Button circular color="twitter" icon="twitter" />
            <Button circular color="linkedin" icon="linkedin" />
            <Button circular color="google plus" icon="google plus" />
          </div>
          <List>
            <List.Item>
              <List.Icon name="world" />
              <List.Content>Origin Thailand</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>Amherst, MA</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <a href="mailto:panupong_lee@me.com">panupong_lee@me.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />
              <List.Content>
                <a href="tel:+18572043916">8572043916</a>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </Segment>
    );
  }
}

SubResearchPage.propTypes = {
  match: PropTypes.string.isRequired
};
