import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, Image, Icon } from "semantic-ui-react";

class ProjectForm extends React.Component {
  state = {};
  render() {
    return (
      <Button basic as={Link} to={`gallery/${this.props.gallery.name}`} style={{ padding: '0' }}>
        <Card>
          <Image
            src={require(`../img/gallery/${
              this.props.gallery.folderName
            }/cover.jpg`)}
          />
          <Card.Content>
            <Card.Header>{this.props.gallery.name}</Card.Header>
            <Card.Meta>
              <span className="date">{this.props.gallery.date}</span>
            </Card.Meta>
            <Card.Description>
              {this.props.gallery.shortDescription}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div>
              <Icon name="plus" />
              More
            </div>
          </Card.Content>
        </Card>
      </Button>
    );
  }
}

ProjectForm.propTypes = {
  gallery: PropTypes.object.isRequired
};

export default ProjectForm;
