import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Project Objective
                </Header>

                <List bulleted>
                  <List.Item>to develop easily complete robotics simulations</List.Item>
                  <List.Item>to use a modern GUI to edit your simulation and your robot controllers.</List.Item>
                  <List.Item>to make robot operate under dynamic environment</List.Item>
                  <List.Item>to make applications can be transferred onto the physical robot easily, or without modification</List.Item>
                </List>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Background
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Using a robotics simulator, you can develop applications for a physical robot without needing to use the physical robot, which saves you money and time while still allowing you to develop applications and transfer these applications from a computer to a real robot without modify codes.

                  <br/>
                  <br/>

                  In this project, a robot and its surroundings are rendered on the screen. It is possible to modify the environment by adding obstacles, other robots, and goals in real time. A physics engine was developed to generate more realistic robot motions.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  KEY POINTS
                </Header>

                <List bulleted>
                  <List.Item>Actuators
                    <List.List>
                      <List.Item>muscle performance</List.Item>
                      <List.Item>DC motors, Lorentz force, Faraday's law, commutation, backward electro-motive force, DC motor performance	</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Control Theory
                    <List.List>
                      <List.Item>open-loop, closed-loop, negative feedback, the spring-mass-damper, free-body diagram, 2nd-order equation of motion, the Proportional-Derivative (PD) controller, harmonic oscillator, under-, over-, and critically-damped</List.Item>
                      <List.Item>Lyapunov's direct method, phase portrait, Laplace transform, stability, the root locus, practical advice about tuning gains</List.Item>
                      <List.Item>time and frequency response, transfer functions, SISO filters, the equilibrium setpoint controller.	</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Kinematics
                    <List.List>
                      <List.Item>non-holonomic constraints, the base translate/rotate control design, average_red_pixel(), visual foveation, mechanisms, spatial relationships</List.Item>
                      <List.Item>translation, rotation (thinking about the direction cosine matrix), indicial notation, inverting the HT, examples</List.Item>
                      <List.Item>inverse kinematics, workspace (reachable, dexterous), Pieper's IK solution, inverse kinematics for robot's arm</List.Item>
                      <List.Item>kinematics of stereo triangulation, hand-eye coordination, Jacobian	</List.Item>
                      <List.Item>Jacobian, force and velocity mappings, quadratic forms</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Dynamics
                    <List.List>
                      <List.Item>Newton's laws, conservation of momentum, moment of inertia, Newton-Euler dynamics</List.Item>
                      <List.Item>the moment of inertia, the inertia tensor, more on the inertial frame, more on Newton-Euler, the computed torque equation</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Control Basis
                    <List.List>
                      <List.Item>an action architecture: state and dynamics, a landscape of attractors, parameterizing the landscape, funnels, co-articulation</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Path Planning
                    <List.List>
                      <List.Item>c-space representation, obstacle dilation, cellular and simplicial decomposition, 2^n trees, visibility graphs, voronoi diagram</List.Item>
                      <List.Item>potential fields, local minima, navigation functions, intro to harmonic functions, Probabilistic Road Maps (PRM), Rapidly-expanding Random Trees (RRT)</List.Item>
                      <List.Item>harmonic functions, excluding local minima using Laplace's equation, occupancy grids, numerical relaxation algorithms in the robot</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Grasping
                    <List.List>
                      <List.Item>screw systems, mobility analysis, contact types, the grasp Jacobian, examples, form and force closure, solving for grasp forces</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>Etc.</List.Item>
                </List>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Motivation
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  When learning robotics, it is more difficult if learners are required to construct an actual robot.
                  The robot simulator will solve this issue. It can be used to create robot prototypes, as well as to develop, test, and validate your artificial intelligence and control algorithms.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Tasks
                </Header>

                <List bulleted>
                  <List.Item>Actuators
                    <List.List>
                      <List.Item>Build a modern GUI for set-up interactive environments</List.Item>
                      <List.Item>Create a physics engine capable of simulating certain physical systems</List.Item>
                      <List.Item>Create a wide variety of applications, such as
                        <List.List>
                          <List.Item>
                            Motor Units
                            <List.List>
                              <List.Item>
                                Arm Kinematics
                              </List.Item>
                            </List.List>
                          </List.Item>
                          <List.Item>Vision
                            <List.List>
                              <List.Item>
                                oculomotor behavior
                              </List.Item>
                              <List.Item>
                                visual reconstruction
                              </List.Item>
                            </List.List>
                          </List.Item>
                          <List.Item>Search Track</List.Item>
                          <List.Item>Stereo Kinematics</List.Item>
                          <List.Item>Kalman</List.Item>
                          <List.Item>Chase Punch</List.Item>
                          <List.Item>Path Planning</List.Item>
                        </List.List>
                      </List.Item>
                    </List.List>
                  </List.Item>
                </List>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                      <Image src={require('../img/projects/RoboticSimulator/7.png')} size='large' centered />
                      <p style={{ fontSize: '1.33em' }}>Image 1. example the app’s layers between simulator and controller</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Test
                </Header>
                <Header as='h4' style={{ fontSize: '1.5em' }}>
                  Example 1: The robot plays with a bouncing ball.
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Demonstrated motor units, Proportional-Derivative (PD) controller, base translation, rotation controllers and visual tracking controls for the robot, describing mechanisms (links, joints, open-, closed-, series- and parallel-kinematic chains, degrees of freedom, configuration space, spatial tasks)
                </p>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        Screenshots
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/1.png')} centered />
                      <Image src={require('../img/projects/RoboticSimulator/2.png')} centered />

                      <p style={{ fontSize: '1.33em' }}>Image 2. the screenshots show the robot plays with a bouncing ball</p>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        Performance analysis
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/3.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <p style={{ fontSize: '1.33em' }}>
                  Summary Result:
                </p>

                <List bulleted>
                  <List.Item>Punch the ball at time = 0.4-0.5</List.Item>
                  <List.Item>First retreat at time = 0.5</List.Item>
                  <List.Item>Second retreat at time = 1.8</List.Item>
                  <List.Item>The robot was at defensive position at time = 0.0 and time > 2.5</List.Item>
                </List>

                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  From the plot, we can see the ball was moving close to the robot from the beginning. The robot hit the ball at time=0.4-0.5. After that, the ball started to move away from the robot since we can see that the distance between the ball and the robot increases. From “Distance between the robot and the defensive position” plot, we can see that the robot tried to move to the defensive position right after it hit the ball at 0.5. However, the ball is still close to the robot, the robot will try to punch the ball again which means that the robot is moving closer to the ball. At a point, the ball was far enough the robot started to move to the defensive position again. As the result, at time=1.8 the distance between the robot and the defensive position
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h4' style={{ fontSize: '1.5em' }}>
                  Example 2: Illustrating the robot’s visual acuity from both eyeballs.
                </Header>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        Screenshots
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/4.png')} centered />

                      <p style={{ fontSize: '1.33em' }}>Image 3. the robot’s visual acuity from both eyeballs</p>


                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  The major axis of ellipsoid is smaller when I placed the ball close to the roger. Moreover, it is also more likely to have the ellipse that contains the true center of the red ball. When the ball is further from the robot, the estimate unknown distance λ_L is greater. It results the Cartesian mean is also greater. Thus, draw_observation will draw the ellipse that has the longer shape. From this, how good the robot can estimate the position of the ball depends on how far of the ball and itself. In this experiment, it is ended up with the scale is 0.001 which can makes the ellipse still contains the true center of the red ball.
                </p>


                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        A plot showing the oculomotor error as a function of time for both eyes in response to three consecutive discrete placements of the red ball in the field of view
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/5.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  When a ball was placed, the oculomotor errors increased significantly. Then, as the robot and eyeballs are turned toward the ball, the oculomotor errors decrease. The result shows that their configurations are a critically damped which the eyes are able to detect the ball and track to the ball quickly.
                </p>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        A plot showing oculomotor error as a function of time for both eyes in response to a moving red ball <u>that bounces back and forth</u> in front of the robot
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/6.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  The amplitude of the plots is decreasing over time as the same time the ball moves with decreasing its speed. Thus, the oculomotor error decrease when the ball moves with slower speed. In other words, the visual tracker can detect the ball better when the ball moves slower. And the wavelength of the plots is increasing over time. It also supports that the ball is moving with decreasing speed. The eyes’ movement is slower than the ball. If the eyes and the ball move with the same speed, the oculomotor error will be very close to zero. Therefore, increase gains for the eye controller to make the robot moves its eyes faster.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h4' style={{ fontSize: '1.5em' }}>
                  Example 3: The robot plays “PONG” game.
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Implemented states supervisor which control robots for a competitive two player scenario which one robot is on the left side and on the right-side use difference techniques to play the game
                </p>



                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        Screenshots
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/8.png')} centered size='big' />

                      <p style={{ fontSize: '1.33em' }}>Table 1. a supervisor table</p>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                      <Image src={require('../img/projects/RoboticSimulator/9.png')} centered size='big' />

                      <p style={{ fontSize: '1.33em' }}>Image: the robots play pong together</p>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <p style={{ fontSize: '1.33em' }}>
                  Problems
                </p>

                <List bulleted>
                  <List.Item><strong>SETPOINTS FILLTER:</strong> the robot does not touch any of the walls or cross mid-court with any part of his body.</List.Item>
                  <List.Item><strong>MOVEMENT:</strong> the robot blocks or moves parallel to the ball. It retreats when the ball is in another side of arena.  When the ball is in its side, the robot tracks and chases the ball until an action such PUNCH or TOUCH progresses.</List.Item>
                  <List.Item><strong>PUNCH:</strong> when the ball is in the range that an arm can reach, the robot punches the ball either left or right hand. </List.Item>
                  <List.Item><strong>LEFT</strong> side of arena
                    <List.List>
                      <List.Item>If the ball is <u>above</u> the robot, the robot will use <u>left</u> hand to punch the ball and rotates <u>clockwise</u> which it helps the robot to punch the ball harder.</List.Item>
                      <List.Item>If the ball is <u>below</u> the robot, the robot will use <u>right</u> hand to punch the ball and rotates <u>counterclockwise </u> which it helps the robot to punch the ball harder.</List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item><strong>RIGHT</strong> side of arena
                    <List.List>
                      <List.Item>If the ball is <u>above</u> the robot, the robot will use <u>right</u> hand to punch the ball and rotates <u>counterclockwise</u> which it helps the robot to punch the ball harder.</List.Item>
                      <List.Item>If the ball is <u>below</u> the robot, the robot will use <u>left</u> hand to punch the ball and rotates <u>clockwise</u> which it helps the robot to punch the ball harder.</List.Item>
                    </List.List>
                  </List.Item>
                </List>

                <p style={{ fontSize: '1.33em' }}>
                  Observation from experiment
                </p>
                Implemented PONG to solve the problem as above

                <List bulleted>
                  <List.Item><strong>SETPOINTS_FILLTER:</strong> works fine since the robot never touches to the wall or crosses the mid-court. From my experiment, increased gains for base translation and rotation to make the robot to move and rotate faster. The robot still never touches the wall or crosses the mid-court.</List.Item>
                  <List.Item><strong>MOVEMENT:</strong> The robot always retreats when the ball is in the opposite side, and also it always be in front of the ball. However, it does not work well when the ball bounces from the wall since sometimes the robot needs to rotate in order to move parallel to the ball.  </List.Item>
                  <List.Item><strong>PUNCH and TOUCH:</strong> The robot always punches the ball and touches the ball if the ball is moving at some speed. However, sometimes it misses the ball because the ball is moving too fast, and therefore the robot cannot reach the position that its arms can reach the ball.</List.Item>
                </List>



                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  The major axis of ellipsoid is smaller when I placed the ball close to the roger. Moreover, it is also more likely to have the ellipse that contains the true center of the red ball. When the ball is further from the robot, the estimate unknown distance λ_L is greater. It results the Cartesian mean is also greater. Thus, draw_observation will draw the ellipse that has the longer shape. From this, how good the robot can estimate the position of the ball depends on how far of the ball and itself. In this experiment, it is ended up with the scale is 0.001 which can makes the ellipse still contains the true center of the red ball.
                </p>


                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        A plot showing the oculomotor error as a function of time for both eyes in response to three consecutive discrete placements of the red ball in the field of view
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/5.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  When a ball was placed, the oculomotor errors increased significantly. Then, as the robot and eyeballs are turned toward the ball, the oculomotor errors decrease. The result shows that their configurations are a critically damped which the eyes are able to detect the ball and track to the ball quickly.
                </p>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                      <Header as='h4' style={{ fontSize: '1.5em' }}>
                        A plot showing oculomotor error as a function of time for both eyes in response to a moving red ball <u>that bounces back and forth</u> in front of the robot
                      </Header>
                      <Image src={require('../img/projects/RoboticSimulator/6.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <p style={{ fontSize: '1.33em' }}>
                  Discussion:
                  <br/>
                  The amplitude of the plots is decreasing over time as the same time the ball moves with decreasing its speed. Thus, the oculomotor error decrease when the ball moves with slower speed. In other words, the visual tracker can detect the ball better when the ball moves slower. And the wavelength of the plots is increasing over time. It also supports that the ball is moving with decreasing speed. The eyes’ movement is slower than the ball. If the eyes and the ball move with the same speed, the oculomotor error will be very close to zero. Therefore, increase gains for the eye controller to make the robot moves its eyes faster.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>


      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
