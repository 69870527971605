import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, Image, Icon } from "semantic-ui-react";

class ResearchForm extends React.Component {
  state = {};
  render() {
    return (
      <Button basic as={Link} to={`research/${this.props.research.name}`}>
        <Card>
          <Image
            src={require(`../img/researches/${
              this.props.research.folderName
            }/cover.jpg`)}
          />
          <Card.Content>
            <Card.Header>{this.props.research.name}</Card.Header>
            <Card.Meta>
              <span className="date">{this.props.research.date}</span>
            </Card.Meta>
            <Card.Description>
              {this.props.research.shortDescription}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div>
              <Icon name="plus" />
              More
            </div>
          </Card.Content>
        </Card>
      </Button>
    );
  }
}

ResearchForm.propTypes = {
  research: PropTypes.object.isRequired
};

export default ResearchForm;
