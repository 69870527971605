const research = [
  {
    name: "Impacts of User Information Use and Distribution to Third-party Companies",
    participates: "P. Leenawarat",
    date: "April 14, 2018",
    pdf: "Impacts_of_user_information_use_and_distribution_to_third_party_companies.pdf",
    description: "The United States is a country that prides itself on property rights. Who controls their personal information on the Internet has been questioned? As more studies of big data containing users' personal information are conducted, the frequency of lawsuits increases. Indeed, our privacy has been transformed into a commodity that can be purchased, sold, and shared.",
    year: "2018"
  },
  {
    name: "Absence of legislation and technology to protect patients' biomedical specimens and genetic information",
    participates: "P. Leenawarat",
    year: "2017",
    date: "April 25, 2017",
    pdf: "Absence of legislation and technology to protect patients' biomedical specimens and genetic information.pdf",
    description: `In biomedical research, analysis of stored human specimens and genetic information can yield valuable information that can be applied to disease prevention and the development of new medical treatments. However, the concept of "acquisition of specimens" has caused controversy."`
  },
  {
    name: "Technology on Interpersonal Communication and Social Relationships",
    participates: "P. Leenawarat",
    year: "2017",
    date: "February 24, 2017",
    description: "Machines are incapable of lying, but humans are. The machines are programmed to perform only those tasks specified by a developer. While technological advancements have significantly improved our way of life, they also blind people to truths and cause them to believe and act based on a piece of information they found on the Internet.",
    pdf: "Technology on Interpersonal Communication and Social Relationships.pdf"
  },
  {
    name: `Human computers were rewarded by computerized mechanisms that replaced their jobs and even their names`,
    participates: "P. Leenawarat",
    year: "2018",
    date: "April 26, 2018",
    description:
      "The first computerized mechanism was invented in the 1820s by Charlse Babbage. However, computers used to wear a skirt during Sir Isaac Newton period. The word “computer” came out around 1613. In early 17th century, computers did not consume electricity but food. Computers were not made of microchips or CPUs. At that time, they were human. Human computers were much powerful for many tasks, such as performing mathematical calculations. Large problems were broken down into discrete parts that were process by hands. Human computers were used in various fields in history of science; physic, astronomy, mathematics, and chemistry.",
    pdf: "Human Computer.pdf"
  }
];

research.forEach((item, i) => {
  item.id = i;
});


export default research;
