const gallery = [
  {
    name: "My Footage Collection",
    folderName: "footage",
    shortDescription:
      "My favarite footage, shooted by Okrymus",
    description: "",
    date: "June 15, 2020",
    img: [
    ],
    vimeo: []
  },
  {
    name: "Star Gazing at Mauna Kea Summit",
    folderName: "StarGazing2021",
    shortDescription:
      "Never disappointed looking at the stars, why?",
    description:
      "Every time when I look at the sky, I see many shiny stars. They are very beautiful; however, they are just a rock. I am never disappointed looking at the stars, the moon, and the sky and reading about the universe. I would be scared of its darkness and silence should the sky be without any stars at night.",
    date: "Sep 2021",
  },
  {
    name: "Aloha!! Hawaii Trip With My Undergraduate Friends",
    folderName: "Hawaii2021",
    shortDescription:
      "Life brings us apart, but some day for sure we will reunion",
    description:
      "Finishing college is when begin our life. Meeting new people, maturing on career but never forget the old friends",
    date: "Aug 2021",
  },
  {
    name: "Graduation 2019",
    folderName: "Graduation2019",
    shortDescription:
      "College was an adventure in which we met wonderful people and accomplished many things together.",
    description:
      "College was an adventure in which we met wonderful people and accomplished many things together.",
    date: "May 2019",
  },
  {
    name: "My Most Favarite History is Maya Civilization",
    folderName: "Maxico2019",
    shortDescription:
      "The Mayans are an interesting civilization and the fact there are still a lot of ethnic Mayans alive today.",
    description: "They had advanced beyond many other cultures in astronomy, mathematics and other areas but never invented the wheel. I am fascinating to see what new things are discovered about the Maya.",
    date: "May, 2018",
    img: [
    ],
    vimeo: []
  },
  {
    name: "Teaching Assistance in Cooking Class at Boston Center For Adult Education",
    folderName: "AssistanceCookingClass",
    shortDescription:
      "",
    description: "Assisted instructor by demonstrated cooking lesson, answering questions from my students, prepareing ingredient for classes",
    date: "May, 2018",
    img: [
    ],
    vimeo: []
  },
  {
    name: "Travel Alone in Istonbul, Turkey",
    folderName: "Turkey2018",
    shortDescription:
      "In the midst of fear, it will be beautiful",
    description: "Sometime traveling alone, it doesn't mean you are alone. I met a new good friend in Istonbul",
    date: "May, 2018",
    img: [
    ],
    vimeo: []
  },
  {
    name: "Canada 2017",
    folderName: "Canada2017",
    shortDescription:
      "In the midst of fear, it will be beautiful",
    description: "Sometime traveling alone, it doesn't mean you are alone. I met a new good friend in Istonbul",
    date: "May, 2018",
    img: [
    ],
    vimeo: []
  },
  {
    name: "Blizzard Hits New England",
    folderName: "Blizzard",
    shortDescription:
      "First snow and first interview in Boston by Sean Proctor from The Boston Globe",
    description: "BOSTON - JANUARY 27: Panupong Leenawarat, of Thailand, takes video of himself walking through the snow at Roberts Playground in Dorchester on Tuesday. Leenawarat, who has been in Boston for six months, said this was his first big snow... Credit to Sean Proctor",
    date: "May, 2018",
    img: [
    ],
    vimeo: []
  }
];

gallery.forEach((item, i) => {
  gallery.id = i;
});

export default gallery;
