import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
              <p style={{ fontSize: '1.33em' }}>
                <u><b>Models:</b></u>Train decision trees with different maximum depths, nearest neighbors with different num- ber of neighbors, and linear models with different regularization parameters.
                <br/>
                <u><b>Data:</b></u> Two datasets:
                <List bulleted>
                  <List.Item>Estimation of the power output of a power plant (7176 samples for training, 2392 samples for testing). Attributes: Measurements of the four variables: temperature, pressure, humidity and exhaust vacuum Output: Electrical energy output</List.Item>
                  <List.Item>Indoor localization system (19937 samples for training, 1111 labels for testing)
                    <List.List>
                      <List.Item>
                        <u>Attributes:</u> Measurements of 400 sensors inside a building (each sensor measures proximity, and
                        outputs a specific value if it does not sense anything)
                      </List.Item>
                      <List.Item>
                        <u>Output:</u> Position in a grid of the user with respect to a fixed coordinate system (two values, x and y)
                      </List.Item>
                    </List.List>
                  </List.Item>
                </List>
              </p>

              <p style={{ fontSize: '1.33em' }}>
              The criteria used to select a variable for a node has a significant impact on the accuracy of the tree. Thus, the strategy for selecting a variable is to scan all inputs and determine the optimal splitting variable and split point, similar to a greedy algorithm. In other words, a decision tree selects a variable that results in the fewest sub-nodes with the lowest error. Typically, decision tree algorithms are greedy, making locally optimum judgments at each node because the method only splits the node optimally once. They make no promise that the global optimal decision tree will be returned. The reason for this is that the trees have no way of knowing in advance whether or not the leaf nodes would produce the best result.
              </p>

              <Header as='h3' style={{ fontSize: '2em' }}>
              Decision trees:
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                For the power plant dataset train 5 different decision trees using the following maximum depths [3, 6, 9, 12, 15]. Using 5-fold cross-validation, estimate the output of sample error for each model              </p>
                <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is 9-maximum depth and the predicted out of sample error for the model is 0.15962109505472974 or round up to 0.160.
                  <br/>
                    • The MAE on the public leaderboard is 0.16072.
                  <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/1.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/1_(b).png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                <p style={{ fontSize: '1.33em' }}>
                  For the indoor localization dataset train 5 different decision trees using the following maximum depths [20, 25, 30, 35, 40]. Using 5-fold cross-validation, estimate the output of sample error for each model              </p>

                  <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is 40-maximum depth and the predicted out of sample error for the model is 4.4569088626963715 or round up to 4.457.
                    <br/>
                    • The MAE on the public leaderboard is 4.09884.
                    <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/2.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/1_(c).png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Nearest neighbors: 
                </Header>
                  <p style={{ fontSize: '1.33em' }}>
                  For the power plant dataset train 5 different nearest neighbors regressors using the following number of neighbors [3, 5, 10, 20, 25]. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>
                  <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is 3-nearest neighbors and the predicted out of sample error for the model is 0.5417155563489366 or round up to 0.542.
                    <br/>
                    • The MAE on the public leaderboard is 0.53095.
                    <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                  </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/3.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/2_(a).png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                  <p style={{ fontSize: '1.33em' }}>
                    For the indoor localization dataset train 5 different nearest neighbors regressors using the following number of neighbors [3, 5, 10, 20, 25]. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>

                  <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is 3-nearest neighbors and the predicted out of sample error for the model is 3.7628701828826365 or round up to 3.763.
                    <br/>
                    • The MAE on the public leaderboard is 3.16736.
                    <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/4.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/2_(b).png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Linear model:
                </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    Train a Ridge and a Lasso linear model using the power plant dataset with the following regular- ization constants α = [10^−6, 10^−4, 10^−2, 1, 10] for each. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>
                  <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is Ridge with 10^-6-Regularization constants a and the predicted out of sample error for the model is 0.19086517684328888 or round up to 0.191.
                    <br/>
                    • The MAE on the public leaderboard is 0.19136.
                    <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                  </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/5.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/4_(b)_1.png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/4_(b)_2.png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                  <p style={{ fontSize: '1.33em' }}>
                    Train a Ridge and a Lasso linear model using the indoor localization dataset with the following regular- ization constants α = [10^−4, 10^−2, 1, 10] for each. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>

                  <p style={{ fontSize: '1.33em' }}>
                    • The model was chosen is Lesso with 10-2-Regularization constants a and the predicted out of sample error for the model is 19.56379377030673 or round up to 19.564.
                    <br/>
                    • The MAE on the public leaderboard is 20.69706.
                    <br/>
                    • The predicted out of sample error on the public leaderboard is close to the test error.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/6.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/4_(c)_1.png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/MLregression/4_(c)_2.png')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
