import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import Footer from "../common/footer";
import Subway_Soiree_img from "../img/journeys/MusicIsUniversal/Subway_Soiree.jpg";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Header as='h3' style={{ fontSize: '2em' }}>
               Music is Universal
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Sometimes the beauty of a painting does not depend on a tangible image but its mean does. The most artworks are created from humans experience and belief. The best example is “The Subway Soiree” by R. Gregory Christie. “Christie has been a commercial artist for over 20 years” (Christie). During being an artist, Christie has gotten many awards and honors. Moreover, his artworks are famous and also appeared in many different locations such as in subway cars, album covers, and children’s books until others approve his works. Once, his artwork appeared around New York City inside subway cars. “The Subway Soiree” illustrates music is universal and brings diverse people together, which is indicated by the painting’s composition, the artist’s technique, and my interpretation.
              </p>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Container>
          <Image bordered rounded size='huge' src={Subway_Soiree_img} centered />
          <p style={{ textAlign: 'center' }}>Subway Soiree by Gregory Christie</p>
        </Container>
      </Segment>

        <Segment style={{ padding: '8em 0em' }} vertical>
          <Container text>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <p style={{ fontSize: '1.33em' }}>
                  The musicians who are performing are the huge composition of the painting. The nine various gentlemen are holding instruments such as a guitar, a violin, a saxophone, and drums at an underground station “MTA” at New York City. The thing indicates why the people are diverse is the color used. They seem to be playing a jazz music judging by the color used and their instruments. In addition, the musicians seem they are focusing on improvising the best performance for the audiences, but their faces turn to their instruments without looking their audiences while the audiences are standing in front of them and applauding. It means they enjoy listening to the music. Even though the musicians do not convict them directly while the music.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                “Christie was a fan of great artists like Jacob Lawrence and Pablo Picasso, who was a huge influence [his painting’s style],” Christie said in an interview, “he started using elongated figures based on Egyptian art but Romare Bearden really broadened his art [“The Subway Soiree] palette" (Scott). Christie used the color to attract audiences. Even though it makes the painting become unrealistic but it is appealing and meaningful.
                </p>
                <p style={{ fontSize: '1.33em', paddingLeft: '2.5rem' }}>
                Christie painted the subway musicians with purple, green and pink because music is universal and, as he notes, "Jazz breaks down walls and the experience goes far beyond the surface. I'm not trying to put out product. I think about how to fill it up properly and make an interesting image" (Scott).
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  This technique shows music involves his life, especially jazz music. Christie’s father fascinated to play jazz music; therefore, jazz music came to his life since he was young. Once, Christie painted a jazz album's cover: Complete 1961 Village Vanguard Recordings, John Coltrane, and also dedicated the article that Ron Scott “Amsterdam News blogger” interviewed him about the painting to Douglas Dunn who was his dear friend and jazz enthusiast; moreover,
                  he said in the interviewed that jazz musician was his dream job (Scott). These mentioned illustrates that he is the big fan jazz genre and music brought his and his friend together.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  In my opinion, I can assume the musicians are unemployed, or their jobs are playing music on the street to earn money because the place where they are performing is not in a proper venue as my first look at the painting. It can be the assumption that their social class is not from the rich background. This causes they to get a less attention from who is walking around them unlike musicians perform in a big auditorium. The artist painted the musicians are not looking at or communicating with their audiences; therefore, they should lose their audiences ordinarily. On the other hand, most audiences participate with the show and enjoy the music because the music draws people who have the same interest.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  These things that the element of the painting, the artist’s style, and my rendition conveys thought the painting that music is similar as a universal language that people can communicate through music. Consequently, many similarities that music shares with other languages, it has the ability to convey emotions and messages to people. “Charles Darwin drew attention to humans' instinctive ability to respond to music [like men and women charm each other with musical notes and rhythm]; we do not need to be trained to do so, as we do with language” (Barnes). It is unavoidable that “music is,” Henry Wadsworth Longfellow called, “the universal language of mankind” (Wish 4).
                </p>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
            <Header as='h4'>
              Work Cited
            </Header>
            <List bulleted>
              <List.Item>Scott, Ron. “R. Gregory Christie: Subway jazz and beyond.” New York Amsterdam News. 2012. Web. 20 Oct. 2015.</List.Item>
              <List.Item>Christie, R. Gregory. “Who is R. Gregory Christie?” Gregarious Art Statements, the official website of R. Gregory Christie. Web. 20 Oct. 2015.</List.Item>
              <List.Item>Barnes, Tom. “Music Really Is the World's Universal Language — Here's the Science Behind It.” Music.mic. 2015. Web. 19 Oct. 2015.</List.Item>
              <List.Item>Wish, David. “A note from the author.” Little kids rock. Web. 20 Oct. 2015.</List.Item>
            </List>
            </Grid.Row>
          </Grid>
        </Container>
        </Segment>

        <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
