import React from "react";
import { Card, Segment } from "semantic-ui-react";
import { Container } from "reactstrap";
import GalleryForm from "../forms/GalleryForm";
import galleries from "../data/galleries";
import StackGrid, { transitions, easings } from "react-stack-grid";
const transition = transitions.scaleDown;

const JourneyPage = () => (
  <Segment style={{ padding: "2em 0em" }} vertical>
    <Container fluid>
      <StackGrid
        monitorImagesLoaded
        columnWidth={300}
        duration={600}
        gutterWidth={40}
        gutterHeight={15}
        easing={easings.cubicOut}
        appearDelay={60}
        appear={transition.appear}
        appeared={transition.appeared}
        enter={transition.enter}
        entered={transition.entered}
        leaved={transition.leaved}>
         {galleries.map(gallery => (
           <GalleryForm gallery={gallery} key={gallery.id} />
         ))}
      </StackGrid>
    </Container>
  </Segment>
);

export default JourneyPage;
