import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container } from "reactstrap";
import coverimg from "../img/journeys/Hoping in Candelabra’s Light/cover.jpg";
import Footer from "../common/footer";
export default class SubProjectPage extends Component {
  state = {};

  render() {
    return (
      <div>

      <Segment style={{ padding: '0em' }} vertical>
        <Container>
          <Image bordered rounded size='huge' src={coverimg} centered />
          <p style={{ textAlign: 'center' }}>Hoping in Candelabra’s Light by Panupong Leenawarat</p>
        </Container>
      </Segment>

      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Header as='h3' style={{ fontSize: '2em' }}>
               Hoping in Candelabra’s Light
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                The piece of my artwork titled Hoping in Candelabra’s Light represents myself in a dark space with holding a candelabra. The major elements which I use in the artwork are graphite and Copic markers on drawing paper, also including charcoal. Especially, in this drawing, the face seems it has some movement and holds my attention on this piece of art.
              </p>
              <p style={{ fontSize: '1.33em' }}>
                The work represents some colorful tattoo on human’s body form. A skull tattoo is a symbol of death while red roses tattoo has a meaning of deep love and a feeling of expectation and desire. In the artwork, the objects such as the candelabra, the face and the tattoo are a colorful representation of emphasis of a focal point in this drawing. Especially, the dominant element is the candelabra because it is the only light source in this drawing. In contrast, the background is a solid black color.
              </p>
              <p style={{ fontSize: '1.33em' }}>
                I use lines and shading which direct the audience’s eye movement around the artwork, but they must start looking from the candelabra then move up to the face. The implied lines are the strands of the left arm that direct the viewers’ eyes to the right side, the middle right and the right bottom. However, the direction to the left side seems discontinued because the top left side is mostly empty which is black negative space. From my gaze, the bottom half of the drawing has weight on the left side while the top half has weight on the right side. Therefore, the drawing is strong enough to balance the bending of the body to the right side.
              </p>
              <p style={{ fontSize: '1.33em' }}>
                This artwork is composed of shapes because it is two-dimensional. Most of the shapes are formed by lines, shading and shifts in contrast. For instance, the body is formed by a shift from the very dark background.  Shading outlining the body in certain places also give form to the shape of the body’s muscles.
              </p>
              <p style={{ fontSize: '1.33em' }}>
                In this piece, the light source is from the candelabra. The light source is the middle bottom of the artwork. I use bright dark tone to represent where the light directly hits the body while where the light does not hit there is darker tone. The use of black and white for the body makes my portrait stand out from the background. Also, the white and black against each other create positive and negative space for the artwork. The contrast between black colors and the white color create variety. The rose in the bottom right corner creates unity because the roses also appear at the left arm’s tattoo.
              </p>
              <p style={{ fontSize: '1.33em' }}>
                With my use of the white color as a symbol of purity, I create variety by adding the tattoo. The tattoo makes the body seem bold and strong and gives a modern look instead of the traditional white and black drawing. The overall purpose of this drawing is to represent spending time hoping without doing anything is waiting for death as the candle blows out.               </p>
              </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Footer/>
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
