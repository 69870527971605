import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Embed,
  Grid
} from "semantic-ui-react";
import { Container } from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";

import journeys from "../data/journeys";
import MusicIsUniversal from "../journeys/MusicIsUniversal";
import Hoping from "../journeys/Hoping in Candelabra’s Light";
import MissingCharge from "../journeys/The Missing Charge";
import ApolloPoem from "../journeys/apolloPoem";
import IAMHORWANG from "../journeys/I AM HORWANG";
import CodeAuthor from "../journeys/codeAuthor";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;
export default class SubProjectPage extends Component {
  state = { activeIndex: 0, numPages: null, pageNumber: 1 };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber - 1 === 0 ? state.numPages : state.pageNumber - 1
    }));
  goToNextPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber + 1 === state.numPages + 1 ? 1 : state.pageNumber + 1
    }));

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  journey = journeys.find(
    item => item.name === this.props.match.params.journeyName
  );
  render() {
    const { activeIndex } = this.state;
    const { active } = this.state;
    const { pageNumber, numPages } = this.state;

    const journeyName = this.props.match.params.journeyName;
    let journeyEleType = journeyName;
    switch (this.journey.name) {
      case 'Music is Universal':
      case 'Hoping in Candelabra’s Light':
      case 'The Missing Charge':
      case 'Apollo Poem 69th Anniversary':
      case 'I AM HORWANG':
      case 'Code Writer = Author':
        break;
      default:
        journeyEleType = 'standard';
    }
    return (
      <div>
      {journeyEleType === 'standard' && (<Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em" }}>
            {this.journey.name}
          </Header>
          <p style={{ fontSize: "1.33em" }}>{this.journey.description}</p>
          <Divider
            as="h4"
            className="header"
            horizontal
            style={{
              margin: "3em 0em",
              textTransform: "uppercase"
            }}
          >
            <a>OVERVIEW</a>
          </Divider>
          {this.journey.img &&
            this.journey.img.map(image => (
              <div>
                <Image
                  centered
                  size="big"
                  src={require(`../img/journeys/${
                    this.journey.folderName
                  }/${image}`)}
                />
                <Divider />
              </div>
            ))}

          {this.journey.vimeo &&
            this.journey.vimeo.map(videoID => (
              <div>
                <Embed
                  autoplay
                  id={videoID[0]}
                  placeholder={require(`../img/journeys/${
                    this.journey.folderName
                  }/${videoID[1]}`)}
                  source="vimeo"
                  hd
                />
                <Divider />
              </div>
            ))}

          {this.journey.youtube &&
            this.journey.youtube.map(videoID => (
              <div>
                <Embed
                  autoplay
                  id={videoID[0]}
                  placeholder={require(`../img/journeys/${
                    this.journey.folderName
                  }/${videoID[1]}`)}
                  source="youtube"
                  hd
                />
                <Divider />
              </div>
            ))}

            {this.journey.pdf && (
              <Grid centered>
                <Grid.Row>
                  <div
                    style={{
                      alignItems: "center",
                      verticalAlign: "middle"
                    }}
                  >
                  <Grid>
                    <Grid.Column floated='left' width={5}>
                      <Button
                        onClick={this.goToPrevPage}
                        content="Prev"
                        icon="left arrow"
                        labelPosition="left"
                      />
                      <Button
                        onClick={this.goToNextPage}
                        content="Next"
                        icon="right arrow"
                        labelPosition="right"
                      />
                    </Grid.Column>
                    <Grid.Column floated='right' width={5}>
                      <Button
                        as="a"
                        target="_blank"
                        href={require(`../data/pdf/${this.journey.pdf}`)}
                        content="Download"
                        icon="download"
                        labelPosition="right"
                      />
                    </Grid.Column>
                  </Grid>
                    <Segment>
                      <Document
                        file={require(`../data/pdf/${this.journey.pdf}`)}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                      >
                        <Page
                          pageNumber={pageNumber}
                          scale={window.innerWidth <= 500 ? "0.6" : "1.5"}
                        />
                      </Document>
                    </Segment>
                  </div>
                </Grid.Row>

                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </Grid>
            )}

          <Header as="h3" style={{ fontSize: "2em" }}>
            Panupong Leenawarat
          </Header>
          <div>
            <Button circular color="facebook" icon="facebook" />
            <Button circular color="twitter" icon="twitter" />
            <Button circular color="linkedin" icon="linkedin" />
            <Button circular color="google plus" icon="google plus" />
          </div>
          <List>
            <List.Item>
              <List.Icon name="world" />
              <List.Content>Origin Thailand</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>Amherst, MA</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <a href="mailto:panupong_lee@me.com">panupong_lee@me.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />
              <List.Content>
                <a href="tel:+18572043916">8572043916</a>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </Segment>)}
      {this.journey.content === 'Music is Universal' && (<MusicIsUniversal/>)}
      {this.journey.content === 'Hoping in Candelabra’s Light' && (<Hoping/>)}
      {this.journey.content === 'The Missing Charge' && (<MissingCharge/>)}
      {this.journey.content === 'Apollo Poem 69th Anniversary' && (<ApolloPoem/>)}
      {this.journey.content === 'I AM HORWANG' && (<IAMHORWANG/>)}
      {this.journey.content === 'codeAuthor' && (<CodeAuthor/>)}
      </div>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
