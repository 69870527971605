const activities = [
  {
    name: "Technology Associate/Software Engineer At Spill Center, Inc",
    location: "Hudson, MA",
    description:
      "Full-Stack Development, Build a completed platform to facilitate alert distribution and emergency response management for clients across a variety of industries. Design creative prototypes according to client needs, Perform unit and integration testing, Troubleshoot and debug existing applications, Deploy and Public production to the cloud server; AWS, User Interface, SQL Database Management, Call center routing, Alert distribution; email and Text",
    date: "2019-Present",
    icon: "briefcase",
    background: "rgb(57,119,93)"
  },
  {
    name: "Bachelor of Science in Computer Science",
    location: "University of Massachusetts Amherst, MA",
    description:
      "Machine Learning, Artificial Intelligence, Web Programming, Robotic Programming, Algorithms, Reasoning Under Uncertainty, Social Issues in Computing, Data Structures, Computer Systems, Principles of Data Science, Practice and Applications of Data Management, Applied Cryptography",
    date: "2017-May 2019",
    icon: "graduation cap",
    background: "rgb(233, 30, 99)"
  },
  {
    name: "Associate Degree in Computer Science",
    location: "Bunker Hill Community College Boston, MA",
    description:
      "Programming, Database, Java, English, Communication, Javascript, Creative Direction, User Experience, Visual Design, Project Management, Team Leading, Mathematics, Management",
    date: "September 2015 - May 2017",
    icon: "graduation cap",
    background: "rgb(233, 30, 99)"
  },
  {
    name: "English Learning",
    location: "The New England School of English (NESE) Boston, MA",
    description:
      "English, Writing Skill, Reading Skill, Oral Communication, Listening Skill, American Culture",
    date: "July 2014 - November 2014",
    icon: "language",
    background: "rgb(187, 143, 206)"
  },
  {
    name: "English Learning",
    location: "Education First (EF) Brighton, MA",
    description:
      "English, Writing Skill, Reading Skill, Oral Communication, Listening Skill, American Culture",
    date: "April 2014 - May 2014",
    icon: "language",
    background: "rgb(187, 143, 206)"
  },
  {
    name: "High School",
    location: "Horwang High School Bangkok, Thailand",
    description:
      "General education, Flash, Computer Hardware, Programming, Discoverd myself",
    date: "2008 - 2014",
    icon: "graduation cap",
    background: "rgb(233, 30, 99)"
  },
  {
    name: "The Chinese Course",
    location: "No.65 Middle School Beijing, China",
    description: "Learning Chinese, Chinese culture, Communication",
    date: "2013",
    icon: "language",
    background: "rgb(187, 143, 206)"
  },
  {
    name: "The Chinese Course",
    location: "Zhejiang Ronghuai International School Zhuji, China",
    description: "Learning Chinese, Chinese culture, Communication",
    date: "2011",
    icon: "language",
    background: "rgb(187, 143, 206)"
  },
  {
    name: "Birthday",
    location: "Bangkok, Thailand",
    description: "First day I saw the world",
    date: "January 3, 1996",
    icon: "birthday cake",
    background: "rgb(161, 803, 398)"
  }
  //   {
  //     activityID: 2,
  //     name: "New York Christmas 2018",
  //     description:
  //       "Cerebrate new year at Maxico City. And being like a king at Cancun",
  //     date: "Dec 12, 2018"
  //   }
];

export default activities;
