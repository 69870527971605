import React from "react";
import { Card, Segment } from "semantic-ui-react";
import { Container } from "reactstrap";
import JourneyForm from "../forms/JourneyForm";
import journeys from "../data/journeys";
import StackGrid, { transitions, easings } from "react-stack-grid";
const transition = transitions.scaleDown;

const JourneyPage = () => (
  <Segment style={{ padding: "2em 0em" }} vertical>
    <Container fluid>
      <StackGrid
        monitorImagesLoaded
        columnWidth={300}
        duration={600}
        gutterWidth={40}
        gutterHeight={15}
        easing={easings.cubicOut}
        appearDelay={60}
        appear={transition.appear}
        appeared={transition.appeared}
        enter={transition.enter}
        entered={transition.entered}
        leaved={transition.leaved}>
         {journeys.map(journey => (
           <JourneyForm journey={journey} key={journey.journeyID} />
         ))}
      </StackGrid>
    </Container>
  </Segment>
);

export default JourneyPage;
