import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import ImageGallery from "react-image-gallery";
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import { Container } from "reactstrap";
import galleries from "../data/galleries";
import ImageModal from "../common/ImageModal";

export default class SubGalleryPage extends Component {
  state = { activeIndex: 0, modalOpen: false, startIndex: 3 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  gallery = galleries.find(
    item => item.name === this.props.match.params.galleryName
  );

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  updateDimensions = () => {
    if (!this.myRef || !this.myRef.current) return;
    this.setState({ width: this.myRef.current.offsetWidth, height: this.myRef.current.offsetHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }




  getDimensions = (myRef) => {
    console.log(myRef);
    // this.setState({ width: myRef.current.offsetWidth, height: myRef.current.offsetHeight });
  };

  render() {
    const { activeIndex } = this.state;
    let imagesLoad = [];
    const directory = `../img/gallery/${this.gallery.folderName}/`
    imagesLoad = require.context('../img/gallery/', true, /\.(png|JPG|jpe?g|svg)$/);
    // const imagesLoad = require.context(`../img/gallery/${this.gallery.folderName}`, false, /\.(png|jpe?g|svg)$/);
    // console.log(this.gallery);
    const importAll = (r) => {
      const images = [];
      r.keys().forEach((item, i) => {
        if (item.indexOf(this.gallery.folderName) > 0) {
          images.push({
            original: r(item),
            thumbnail: r(item)
          })
        }
      });

      return images;
    }
    const images = importAll(imagesLoad);


    const items = images.map((ele, index) => {
      return {
        itemId: String(index),
        mediaUrl: ele.original,
        metaData: {
          type: 'image'
        }
      }
    });

    // The options of the gallery (from the playground current state)
    const options = {
      galleryLayout: 1,
      cubeType: 'fit'
    };

    const eventsListener = (eventName, eventData) => {
      if (eventName === 'ITEM_CLICKED') {
         this.setState({ startIndex: eventData.idx });
         this.setState({ modalOpen: true })
      }
    };


    const scrollingElement = window;

    const container = {
      width: this.state.width,
      height: this.state.height
    };

    return (
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <div ref={this.myRef}>

          <Header as="h3" style={{ fontSize: "2em" }}>
            {this.gallery.name}
          </Header>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              Description
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                {this.gallery.description
                  ? this.gallery.description
                  : this.gallery.shortDescription}
              </p>
            </Accordion.Content>
          </Accordion>

          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
            <a>OVERVIEW</a>
          </Divider>

          <ImageModal // The invisible modal itself
             key='modal1'
             modalOpen={this.state.modalOpen}
             images={images}
             startIndex={this.state.startIndex}
             handleClose={
               () => {
                 this.setState({ modalOpen: false })
               }
             }
             valueIntoModal={this.state.valueIntoModal}
          />

          <ProGallery
            items={items}
            container={container}
            options={options}
            scrollingElement={scrollingElement}
            eventsListener={eventsListener}
          />
          <Header as="h3" style={{ fontSize: "2em" }}>
            Panupong Leenawarat
          </Header>
          <div>
            <Button circular color="facebook" icon="facebook" />
            <Button circular color="twitter" icon="twitter" />
            <Button circular color="linkedin" icon="linkedin" />
            <Button circular color="google plus" icon="google plus" />
          </div>
          <List>
            <List.Item>
              <List.Icon name="world" />
              <List.Content>Origin Thailand</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>Amherst, MA</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <a href="mailto:panupong_lee@me.com">panupong_lee@me.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />
              <List.Content>
                <a href="tel:+18572043916">8572043916</a>
              </List.Content>
            </List.Item>
          </List>
          </div>
        </Container>
      </Segment>
    );
  }
}

SubGalleryPage.propTypes = {
  match: PropTypes.string.isRequired
};
