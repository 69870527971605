import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Background
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Given a 3×3 board with 8 tiles (every tile has one number from 1 to 8) and one empty space. The objective is to place the numbers on tiles to match the final configuration using the empty space. We can slide four adjacent (left, right, above, and below) tiles into the empty space.
                </p>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                      <Image src={require('../img/projects/8-Puzzle/1.png')} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <p style={{ fontSize: '1.33em' }}>
                  For the project, A-star Search Algorithm in Artificial Intelligence was used to solve the puzzle. A* is a search algorithm, or a best-first search, meaning that it is formulated in terms of weighted graphs: starting from a specific starting node of a graph, it aims to find a path to the given goal node having the smallest cost. It does this by maintaining a tree of paths originating at the start node and considering both path cost and heuristic information in its selection of which path to expand until its termination criterion is satisfied.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Motivation
                </Header>
                <List bulleted>
                  <List.Item>A* is indeed a very powerful algorithm used to increase the performance of artificial intelligence.</List.Item>
                  <List.Item>A* can be used as an optimization tool to optimize various programs including ML applications by finding hyperparameters and etc.</List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Tasks
                </Header>

                <List bulleted>
                  <List.Item><p style={{ fontSize: '1.33em' }}>Implement A* with the following heuristics in Python.</p>
                    <List.List>
                      <List.Item>
                        <p style={{ fontSize: '1.33em' }}>
                          Manhattan distance - The distance between two points measured along axes at right angles.
                          <Image src={require('../img/projects/8-Puzzle/4.png')} size='media' />
                        </p>
                      </List.Item>
                      <List.Item>
                        <p style={{ fontSize: '1.33em' }}>
                          RowCol - the number of tiles out of the correct row position plus the number of tiles out of the correct column position.
                          <Image src={require('../img/projects/8-Puzzle/5.png')} size='media' />
                        </p>
                      </List.Item>
                      <List.Item>
                        <p style={{ fontSize: '1.33em' }}>
                          Misplaced Tiles - the number of tiles out of place.
                          <Image src={require('../img/projects/8-Puzzle/6.png')} size='media' />
                        </p>
                      </List.Item>
                      <List.Item>
                        <p style={{ fontSize: '1.33em' }}>
                          Null Heuristics - heuristics is always 0 (return 0). “Brute-Force”
                          <Image src={require('../img/projects/8-Puzzle/7.png')} size='media' />
                        </p>
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>
                    <p style={{ fontSize: '1.33em' }}>
                      Use PyQt library to develop GUI
                    </p>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Test
                </Header>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                      <Image src={require('../img/projects/8-Puzzle/2.png')} size='small' centered />
                      <p style={{ fontSize: '1.33em' }}>Image 1. the program prints each state and action to reach the goal for the simple puzzle</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Performance
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  For each of the heuristic methods above run 100 random start states. For each trial compute the number of nodes expanded. Compare the mean and standard deviation of the number of nodes expanded for each heuristic method.
                </p>

                <Grid celled='internally' columns='equal' stackable>
                  <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                      <Image src={require('../img/projects/8-Puzzle/3.png')} size='large' centered />
                      <p style={{ fontSize: '1.33em' }}>Table 1: Number of nodes expanded during A* search on the 8-Puzzle problem</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  Conclusion
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Form the table, Manhattan distance method can find a solution with the fewest number of expanded nodes. The search with Manhattan distance method is the most effective to find an optimal solution among the other methods. Therefore, the method is the best way to find an optimal solution for the eight-puzzle program. On the other hand, NULL method is the worst method because it significantly expanded more nodes than the others and needed more time to find a solution. As we known the method always returns 0, it does not provide any information which is useful for the search to find a solution.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
