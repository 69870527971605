import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid,
  Embed,
  Card,
  Menu
} from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import GifPlayer from "react-gif-player";
import ReactMarkdown from 'react-markdown'

import { Container } from "reactstrap";
import projects from "../data/projects";
import Nqueen from "../pages/game/N-queen/Nqueen";
import ImageClassification from "../projects/ImageClassification.js";
import MLregression from "../projects/MLregression.js";
import TumorDetection from "../projects/TumorDetection.js";
import VigenèreCipher from "../projects/Vigenère Cipher.js";
import RoboticSimulator from "../projects/RoboticSimulator.js";
import Puzzle8 from "../projects/Puzzle8.js";
import Nqueens from "../projects/Nqueens.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;
export default class SubProjectPage extends Component {
  state = { activeIndex: 0, numPages: null, pageNumber: 1 };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber - 1 === 0 ? state.numPages : state.pageNumber - 1
    }));
  goToNextPage = () =>
    this.setState(state => ({
      pageNumber:
        state.pageNumber + 1 === state.numPages + 1 ? 1 : state.pageNumber + 1
    }));

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  project = projects.find(
    item => item.name === this.props.match.params.projectName
  );
  render() {
    const { activeIndex } = this.state;
    const { active } = this.state;
    const { pageNumber, numPages } = this.state;

    return (
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em" }}>
            {this.project.name}
          </Header>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              Description
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                {this.project.description
                  ? this.project.description
                  : this.project.shortDescription}
              </p>
            </Accordion.Content>

            {this.project.team && (
              <div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Team members
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <List animated bulleted verticalAlign="middle">
                    {this.project.team.map(person => (
                      <List.Item key={person}>
                        <p>{person}</p>
                      </List.Item>
                    ))}
                  </List>
                </Accordion.Content>
              </div>
            )}
            {this.project.award && (
              <div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Award
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>{this.project.award}</p>
                </Accordion.Content>
              </div>
            )}
            {(this.project.demo || this.project.sourceCode || this.project.downloadLink) && (
              <div>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Link
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <List animated verticalAlign="middle">
                    {this.project.demo && (
                      <List.Item>
                        <List.Icon name="play circle" />
                        <List.Content>
                          <a href={this.project.demo} target="blank">DEMO</a>
                        </List.Content>
                      </List.Item>
                    )}
                    {this.project.downloadLink && (
                      <List.Item>
                        <List.Icon name="code" />
                        <List.Content>
                          <a
                            target="_blank"
                            href={require(`../data/files/${this.project.downloadLink}`)}
                            download
                            >
                            Download
                          </a>
                        </List.Content>
                      </List.Item>
                    )}
                    {this.project.sourceCode && (
                      <List.Item>
                        <List.Icon name="code" />
                        <List.Content>
                          <a href={this.project.sourceCode} target="blank">Source</a>
                        </List.Content>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>
              </div>
            )}
          </Accordion>

          {(this.project.demo || this.project.sourceCode || this.project.downloadLink) && (
            <div>
            <br/>
            <Card.Group centered>
              {this.project.demo && (
                <Card
                  href={this.project.demo}
                  target="_blank"
                  style={{ padding: '1rem'}}
                >
                  <Header as='h4' icon textAlign='center'>
                    <Icon name='play' circular />
                    <Header.Content>DEMO</Header.Content>
                  </Header>
                </Card>
              )}
              {this.project.downloadLink && (
                <Card
                  href={require(`../data/files/${this.project.downloadLink}`)}
                  target="_blank"
                  style={{ padding: '1rem'}}
                >
                  <Header as='h4' icon textAlign='center'>
                    <Icon name='download' circular />
                    <Header.Content>Download</Header.Content>
                  </Header>
                </Card>
              )}
              {this.project.sourceCode && (
                <Card
                  href={this.project.sourceCode}
                  target="_blank"
                  style={{ padding: '1rem'}}
                >
                  <Header as='h4' icon textAlign='center' >
                    <Icon name='code' circular />
                    <Header.Content>View Source</Header.Content>
                  </Header>
                </Card>
              )}
            </Card.Group>
            </div>
          )}
          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em", textTransform: "uppercase" }}
          >
            <a>OVERVIEW</a>
          </Divider>
          {this.project.gif && (
            <Grid centered>
              {this.project.gif.map(file => (
                <div>
                  {file[0] && (
                    <Header
                      as="h3"
                      style={{ fontSize: "1.5em", marginTop: "1.5em" }}
                      textAlign="left"
                    >
                      {file[0]}
                    </Header>
                  )}
                  <GifPlayer
                    style={{
                      width:
                        window.innerWidth <= 500 ? window.innerWidth : "100%"
                    }}
                    gif={require(`../img/projects/${this.project.name}/${
                      file[1]
                    }`)}
                    autoplay
                  />
                </div>
              ))}
            </Grid>
          )}
          {this.project.pdf && (
            <Grid centered>
              <Grid.Row>
                <div
                  style={{
                    alignItems: "center",
                    verticalAlign: "middle"
                  }}
                >
                <Grid>
                  <Grid.Column floated='left' width={5}>
                    <Button
                      onClick={this.goToPrevPage}
                      content="Prev"
                      icon="left arrow"
                      labelPosition="left"
                    />
                    <Button
                      onClick={this.goToNextPage}
                      content="Next"
                      icon="right arrow"
                      labelPosition="right"
                    />
                  </Grid.Column>
                  <Grid.Column floated='right' width={5}>
                    <Button
                      as="a"
                      target="_blank"
                      href={require(`../data/pdf/${this.project.pdf}`)}
                      content="Download"
                      icon="download"
                      labelPosition="right"
                    />
                  </Grid.Column>
                </Grid>
                  <Segment>
                    <Document
                      file={require(`../data/pdf/${this.project.pdf}`)}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                      <Page
                        pageNumber={pageNumber}
                        scale={window.innerWidth <= 500 ? "0.6" : "1.5"}
                      />
                    </Document>
                  </Segment>
                </div>
              </Grid.Row>

              <p>
                Page {pageNumber} of {numPages}
              </p>
            </Grid>
          )}
          {this.project.img &&
            this.project.img.map(image => (
              <Grid centered key={image}>
                <div>
                  <Image
                    src={require(`../img/projects/${
                      this.project.name
                    }/${image}`)}
                  />
                </div>
              </Grid>
            ))}
            {this.project.markdown && <ReactMarkdown children={this.project.markdown} />}
          {this.project.name === "N-Queen" && (
            <Grid centered>
              <Nqueen />
            </Grid>
          )}
          {this.project.content === 'ImageClassification' && (<ImageClassification/>)}
          {this.project.content === 'MLregression' && (<MLregression/>)}
          {this.project.content === 'TumorDetection' && (<TumorDetection/>)}
          {this.project.content === 'VigenèreCipher' && (<VigenèreCipher/>)}
          {this.project.content === 'RoboticSimulator' && (<RoboticSimulator/>)}
          {this.project.content === 'Puzzle8' && (<Puzzle8/>)}
          {this.project.content === 'Nqueens' && (<Nqueens/>)}
          {this.project.content === 'Amath Game' && (
            <Embed
               active
               url='https://prezi.com/embed/7y53uqhk82v5/'
             />
          )}

          <Header as="h3" style={{ fontSize: "2em" }}>
            Panupong Leenawarat
          </Header>
          <div>
            <Button circular color="facebook" icon="facebook" />
            <Button circular color="twitter" icon="twitter" />
            <Button circular color="linkedin" icon="linkedin" />
            <Button circular color="google plus" icon="google plus" />
          </div>
          <List>
            <List.Item>
              <List.Icon name="world" />
              <List.Content>Origin Thailand</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>Amherst, MA</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <a href="mailto:panupong_lee@me.com">panupong_lee@me.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />
              <List.Content>
                <a href="tel:+18572043916">8572043916</a>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </Segment>
    );
  }
}

SubProjectPage.propTypes = {
  match: PropTypes.string.isRequired
};
