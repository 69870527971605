import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid,
  Container
} from "semantic-ui-react";

class Footer extends React.Component {
  state = {};
  render() {
    return (
      <Segment inverted vertical style={{ padding: "5em 0em" }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Contact Me" />
                <div>
                  <Button
                    href="https://www.facebook.com/panupong.leenawarat"
                    target="_blank"
                    circular
                    color="facebook"
                    icon="facebook"
                  />
                  <Button
                    href="https://www.linkedin.com/in/panupong-lee/"
                    target="_blank"
                    circular
                    color="linkedin"
                    icon="linkedin"
                  />
                  <Button
                    href="https://github.com/okrymus"
                    target="_blank"
                    circular
                    color="grey"
                    icon="github"
                  />
                </div>
                <List>
                  <List.Item>
                    <List.Icon name="world" />
                    <List.Content>Origin Thailand</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="marker" />
                    <List.Content>Amherst, MA</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="mail" />
                    <List.Content>
                      <a href="mailto:panupong_lee@me.com">panupong_lee@me.com</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="phone" />
                    <List.Content>
                      <a href="tel:+18572043916">8572043916</a>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

Footer.propTypes = {
};

export default Footer;
