import React, { Component } from "react";
import {
  Accordion,
  Container,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Image,
  List,
  Grid
} from "semantic-ui-react";
import PropTypes from "prop-types";

class ImageClassification extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  };
  render() {
    return (
      <div>
        <Segment style={{ padding: '2em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column>
              <p style={{ fontSize: '1.33em' }}>
                The goal is to train a model to recognize which of the objects is present in an image. Some samples of the training images are:
              </p>
              <Image fluid centered src={require('../img/projects/Blurry-Noisy Image Classification/1.png')} />

              <Header as='h3' style={{ fontSize: '2em' }}>
              Decision trees:
              </Header>
              <p style={{ fontSize: '1.33em' }}>
              Train 5 different decision trees using the following maximum depths [3, 6, 9, 12, 14]. Using 5-fold cross-validation, estimate the out of sample error for each model
              </p>
                <p style={{ fontSize: '1.33em' }}>
                The tree is more complex as we increase its max depth. The estimated accuracy shifts upward when max depth is increased between 3 and 9 because our models are underfitting. Where the max depth is greater than 9, the decision tree might simply overfit the training data without capturing useful patterns; this causes estimate accuracy to decrease.
                <br/>
                <br/>
                The model with lowest estimated out of sample error is with 9-max_depth.
                <br/>
                The out of sample error for the model: 0.48780
                <br/>
                Test error from the full test data set: 0.42075
                <br/>
                The predicted out of sample error is close to my error on the test set.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/2.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/Decision Tree Classifier.jpg')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 1. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>

                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Nearest neighbors:
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    Train 5 different nearest neighbors classifiers using the following number of neighbors [3,5,7,9,11]. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>
                  <p style={{ fontSize: '1.33em' }}>
                  The model with lowest estimated out of sample error is with 3-neighbors The out of sample error for the model: 0.44724
                  <br/>
                  Test error from the full test data set: 0.38577
                  <br/>
                  Yes, the predicted out of sample error is close to my error on the test set.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/3.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 2. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/DKNeighborsClassifier.jpg')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 2. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>

                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Neural networks:
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    Train 3 neural networks using [5, 40, 70] hidden-layer neurons. Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>
                  <p style={{ fontSize: '1.33em' }}>
                    The model with lowest estimated out of sample error is with 70-hidden-layer neurons.
                    <br/>
                    The out of sample error for the model: 0.30590
                    <br/>
                    Test error from the full test data set: 0.20095
                    <br/>
                    The predicted out of sample error is close to my error on the test set; however, the different between their values about 0.1.
                    <br/>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/4.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 3. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/Neural networks.jpg')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 3. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>

                  <Header as='h3' style={{ fontSize: '2em' }}>
                    Linear model:
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    Train a linear model using λ ∈ [0, 100] and α ∈ [10^−6, 10^−4, 10^−2, 1, 10] (you will train 10 classifiers). Using 5-fold cross-validation, estimate the out of sample error for each model
                  </p>
                  <p style={{ fontSize: '1.33em' }}>
                    The model with lowest estimated out of sample error is with λ=100 and α=10.
                    <br/>
                    The out of sample error for the model: 0.25000
                    <br/>
                    Test error from the full test data set: 0.26286
                    <br/>
                    Yes, the predicted out of sample error is close to my error on the test set.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/5.png')} />
                  <p style={{ fontSize: '1.33em' }}>Table 4. Estimate the out of sample error for each model</p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Image src={require('../img/projects/Blurry-Noisy Image Classification/Linear model.jpg')} size='medium' centered />
                  <p style={{ fontSize: '1.33em' }}>Figure 4. Estimate the out of sample error for each model</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment style={{ padding: '2em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
              <Grid.Row>
                <Grid.Column>
                <p style={{ fontSize: '1.33em' }}>
                  The classification algorithm worked the best is neural networks because it gives the lowest error.
                  However, there were trade-offs between them. Neural networks are often more flexible than the others,
                  better classification and nonparametric(automatically learn intermediate representations “hidden layers”),
                  but neural networks require very large amounts of data to train.
                </p>

                <p style={{ fontSize: '1.33em' }}>
                  The main reason is neural networks are a nonlinear algorithm with multi-layer processing. Its predictions are vary based on the specific data used to train them. If we have a better and more data set to train the NN, the accuracy will increase more. In term of efficiency, from what we have done so far, I found that neural networks take less time to compute and predict the result with using the same training data set. The reason is its neurons were taught to recognize various specific patterns when we fit training data and after its neurons decide whether to fire or not when test is received.
                </p>

                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Segment>

      </div>
    );
  }
}

export default ImageClassification;

// import React, { Component } from "react";
// import PropTypes from "prop-types";

// import "./board.css";

// class Board extends Component {
//   // static propTypes = {
//   //   moves: PropTypes.any.isRequired,
//   //   isActive: PropTypes.bool
//   // };

//   // onClick = id => {
//   //   if (this.isActive(id)) {
//   //     this.props.moves.clickCell(id);
//   //     console.log("test");
//   //   }
//   // };
//   constructor() {
//     super();

//     this.state = {
//       actives: []
//     };

//     // this.actives = [];
//   }

//   onClick = id => {
//     if (!this.isActive(id)) {
//       this.state.actives.push(id);
//     } else {
//       const filteredItems = this.state.actives.filter(item => item !== id);
//       this.state.actives = filteredItems;
//     }

//     this.setState(this.state.actives);

//     console.log(id);
//   };

//   isActive(id) {
//     return this.state.actives.find(element => element === id);
//   }

//   render() {
//     let tbody = [];

//     for (let i = 0; i < 3; i++) {
//       let cells = [];
//       for (let j = 0; j < 3; j++) {
//         const id = 3 * i + j;
//         cells.push(
//           <td
//             key={id}
//             className={this.isActive(id) ? "active" : ""}
//             onClick={() => this.onClick(id)}
//           />
//         );
//       }
//       tbody.push(<tr key={i}>{cells}</tr>);
//     }

//     return (
//       <div>
//         <table id="board">
//           <tbody>{tbody}</tbody>
//         </table>
//       </div>
//     );
//   }
// }

// export default Board;
